import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { QueryService } from '../services/query.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router'
import { DataService } from '../services/data.service';
import { getCMS } from './gql/query.gql';
import { CMS_TYPE, footerSiteSettings } from '../header/types/header.query.types';
import { siteSettingsResponse } from '../header/types/header.output.types';
import {TranslateService} from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Title } from '@angular/platform-browser';
import { CMS_Data } from '../p2p/common-query-grapgql/paymentmethod.query';
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit, ErrorStateMatcher {
  siteName: any;
  playStore: string;
  appStore: string;
  cmsType: any = [];
  cmsDetails: any;

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  animal: string;
  name: string;
  footerContent: string;
  fblink: string;
  instaLink: string;
  twitterLink: string;
  linkedinLink: string;
  youtubeLink: string;
  site_name: string;
  loading = false;
  contactEmail: string;
  langValue: string;
  load(): void {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 3500)
  }

  currentURL: string;
  constructor(
    public dialog: MatDialog, 
    private QueryService: QueryService,
    public translate: TranslateService,
    private cookieService: CookieService,
    private titleService: Title,
    private router: Router,
    ) { 
    this.translate.addLangs(['en','tr']);
    this.currentURL = window.location.href.split("#")[0];
  }

  ngOnInit() {
    const footerhight = document.getElementById('cls_footer').clientHeight
    document.getElementById('cls_main').style.minHeight = 'calc(100vh - '+footerhight+'px)';
    //this.getCMS()
    this.getSiteData();
    this.getCMSType();
  }

  getCMSType() {
    const getType = {
      CMSTypeData: {
        lang: {
          lang_code: localStorage.getItem("lang")
        }   
      }
    }
    this.QueryService.query(CMS_TYPE, getType, 'network-only').subscribe(data => {
      this.cmsType = data.data.getCMSType.result
      this.getCMSData();
     
    })
  }

  getCMSData() {
    const fetchCMSData = {
      cmcData: {
        // currency_code:"USDT",
        footer: true,
        type : { $nin: ["P2P", "P2P Mobile"] },
        lang: {"lang_code": localStorage.getItem("lang")}
      }
    }
     const fetchP2PCurrencyQuery = this.QueryService.query(CMS_Data, fetchCMSData, 'network-only').subscribe(res => {
      let response     = res.data.fetchCMS.result
      this.cmsDetails = response      

      fetchP2PCurrencyQuery.unsubscribe();
    })
 }

 navigatePage(data) {
    let changeValue = data.replaceAll(' ', '_')    
    this.router.navigate(["/footer-content-view"],
      {
        queryParams: { type: changeValue },
        queryParamsHandling: 'merge'
      }
    );
 }

  getSiteData(){
    this.QueryService.query(footerSiteSettings, null, 'network-only').subscribe(data => {
      let response = data.data.getDefaultCurrency as siteSettingsResponse
      this.site_name = response.result.site_name;
       this.fblink = response.result.join_us.Facebook;
       this.instaLink = response.result.join_us.Instagram;
       this.twitterLink = response.result.join_us.Twitter;
       this.linkedinLink = response.result.join_us.Linkedin;
       this.youtubeLink = response.result.join_us.Youtube;
       this.playStore = response.result.join_us.Play_store;
       this.appStore = response.result.join_us.App_store;
       this.contactEmail = response.result.join_us.contact_mail;
       this.cookieService.set("siteName",this.site_name)
       this.siteName = this.cookieService.get("siteName")
       this.titleService.setTitle(this.siteName);
    })
  }

  // onlangChange(data){
  //   console.log(data);
  //   localStorage.setItem("lang",data)
  //   this.translate.use(data)
  //   this.langValue = localStorage.getItem("lang")
  // }

  onNavigate(url: string){
    window.open(url, "_blank");
  }


  // getCMS() {
  //   const getCMSData = {
  //     CMSQueryData: {
  //       queryData: {
  //         // lang: {
  //         //   lang_code: "en"
  //         // }
  //       },
  //       sort: {
  //         created_date: -1
  //       },
  //       skip: 0,
  //       limit: 100
  //     }
  //   }

  //   this.getCMSQuery(getCMSData);
  // }

  // getCMSQuery(getCMSData) {  
  //   this.QueryService.query(getCMS, getCMSData, "no-cache").subscribe((response: any) => {
  //     const responseData = response.data && response.data.getCMS.result.data;
  //     let renderData = responseData.filter(x => x.url === "Footer HomePage").map(function(z){
  //       let retunedData = z.lang.filter(v => v.lang_code === localStorage.getItem("lang")).map(f => {
  //         return f.content;
  //     })
  //     return retunedData
  //   });
  //    this.footerContent = renderData[0]
  //   })
  // }


  public GooglePopup(): void {
    const dialogRef = this.dialog.open(GooglePopupComponent, {
      width: '500px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

@Component({
  selector: 'google-popup',
  templateUrl: './google-popup/google-popup.component.html'
})


export class GooglePopupComponent {
  verify: any = {}
  code = ''
  @ViewChild('value1', { static: true }) value1: ElementRef;
  @ViewChild('value2', { static: true }) value2: ElementRef;
  @ViewChild('value3', { static: true }) value3: ElementRef;
  @ViewChild('value4', { static: true }) value4: ElementRef;
  @ViewChild('value5', { static: true }) value5: ElementRef;
  @ViewChild('value6', { static: true }) value6: ElementRef;
  values: any = [];
  token: String = "";

  closeDialog(){
    this.dialogRef.close();
  }

  constructor(
    public dialogRef: MatDialogRef<GooglePopupComponent>,
    public router: Router,

    @Inject(MAT_DIALOG_DATA)
    public data: DialogData,
    public dialog: MatDialog,
    private data1: DataService) { 
      this.data1.currentMessage.subscribe((message) => {
        this.token = message
      })
    }


  onKeyUp(event, index) {
    if (event.target.value.length != 1) {
      // this.setFocus(index - 2);
    } else {
      this.values.push(event.target.value);
      this.setFocus(index);
    }
    event.stopPropagation();
  }
  
  keyDownEvent(event, index) {
    // if(event.code === "Backspace" && event.target.value == "") {
    //   this.setFocus(index - 2);
    //   event.stopPropagation();
    // }
    if(event.code === "Backspace" && event.target.value == "") {
      this.setFocus(index - 2);
    } 

    if(!isNaN(event.key)) {
        if(index == 1 && this.verify.value1 && !isNaN(event.key)) {
            this.verify.value1 = event.key
        } else if(index == 2 && this.verify.value2) {
            this.verify.value2 = event.key
        } else if(index == 3 && this.verify.value3) {
            this.verify.value3 = event.key
        } else if(index == 4 && this.verify.value4) {
            this.verify.value4 = event.key
        } else if(index == 5 && this.verify.value5) {
            this.verify.value5 = event.key
        } else if(index == 6 && this.verify.value6) {
            this.verify.value6 = event.key
        }
    }
  }
  
  setFocus(index) {

    switch (index) {
      case 0:
        this.value1.nativeElement.focus();
        break;
      case 1:
        this.value2.nativeElement.focus();
        break;
      case 2:
        this.value3.nativeElement.focus();
        break;
      case 3:
        this.value4.nativeElement.focus();
        break;
      case 4:
        this.value5.nativeElement.focus();
        break;
      case 5:
        this.value6.nativeElement.focus();
        break;
    }
  }

  onPaste(event: ClipboardEvent) {
    this.verify.value1 = event.clipboardData.getData('text')[0]
    this.verify.value2 = event.clipboardData.getData('text')[1]
    this.verify.value3 = event.clipboardData.getData('text')[2]
    this.verify.value4 = event.clipboardData.getData('text')[3]
    this.verify.value5 = event.clipboardData.getData('text')[4]
    this.verify.value6 = event.clipboardData.getData('text')[5]
    this.value6.nativeElement.focus();
  }

  

  
  loading: boolean = false;
  submit() {
    this.loading = true;
    let code = this.code.concat(this.verify.value1, this.verify.value2, this.verify.value3, this.verify.value4, this.verify.value5, this.verify.value6)
    if((this.token == "" || this.token == null || this.token == undefined) && code.length === 6){      
      let obj = {
        onecode: code,
        token: this.token
      }
      this.loading = false;
    }else{
      let obj = {
        onecode: code,
      }
      this.loading = false;
    }
  }



}
