import gql from 'graphql-tag';

export const GET_USER_BY_JWT = gql`query getUserByJWT{
    getUserByJWT{
      status_code
      status_message
      result{
        kyc{
          id_proof{
            first_name
            last_name
            middle_name
            dob
            State
            Address1
            country
            city
            zip
            id_proof_type
            id_proof_front
            id_proof_front_status
            id_proof_back
            id_proof_back_status
            id_proof_front_reject_reason
            id_proof_back_reject_reason
          }
          facial_proof{
            facial_proof
            facial_proof_Status
            facial_proof_reject_reason
          }
          kyc_status
        }
        time_zone
        timezone_name
        profile_image
      }
    }
  }`