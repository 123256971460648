import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
 value: any
 value1: any
 mailValue: any
  
 private messageSource = new BehaviorSubject(this.value);
  currentMessage = this.messageSource.asObservable();
 
  constructor() { }

  changeMessage(message) {
    this.messageSource.next(message)
  }

  private tokendata = new BehaviorSubject(this.value1);
  tokenService = this.tokendata.asObservable();

  savetoken(token){
    this.tokendata.next(token)
  }


  private maildata = new BehaviorSubject(this.value1);
  tempService = this.maildata.asObservable();

  saveEmail(token){
    this.maildata.next(token)
  }


  private tokenTypeData = new BehaviorSubject(this.value1);
  tokenTypeService = this.tokenTypeData.asObservable();

  savetokenType(data){
    this.tokenTypeData.next(data)
  }


  private newMailData = new BehaviorSubject(this.value1);
  newMailService = this.newMailData.asObservable();

  newMailPasswordService(data){
    this.newMailData.next(data)
  }

  private authServiceData = new BehaviorSubject(this.value1);
  authLoginService = this.authServiceData.asObservable();

  authLoginServiceData(data){
    this.authServiceData.next(data)
  }

  chartTheme: Subject<string> = new Subject();

}