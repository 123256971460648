import gql from 'graphql-tag';

export const GET_USER_BY_JWT= gql`query getUserByJWT{
    getUserByJWT{
      status_code
      status_message
      result{
        anti_phishing_code
        tfa_status
      }
    }
  }`