import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  constructor(private apollo: Apollo) { }

  query(query, data, fetchPolicy): Observable<any> {
    return this.apollo
    .watchQuery({ query: query, variables: data, fetchPolicy: fetchPolicy })
    .valueChanges
    .catch(this.handleError);
  }

  private handleError(error: any) {    
      let errMsg = (error.message) ? error.message :
          error.status ? `${error.status} - ${error.statusText}` : 'Server error';      
      return Observable.throw(errMsg);
  }
}
