import {NgModule} from '@angular/core';
import {ApolloModule, Apollo} from 'apollo-angular';
import {HttpLinkModule} from 'apollo-angular-link-http';
import { HttpLink } from "apollo-link-http";
import {InMemoryCache} from 'apollo-cache-inmemory';
import { environment } from '../environments/environment'
import { HttpClientModule } from '@angular/common/http';
import { ApolloLink, Observable } from 'apollo-link';
import { CookieService } from 'ngx-cookie-service';
import { onError } from "apollo-link-error";
import { Router } from '@angular/router';
import {DEVICE_LOGOUT, GET_REFRESH_TOKEN} from "../app/header/types/header.query.types"
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwtDecode from "jwt-decode";
import cryptojs from 'crypto-js';
import { MutationService } from './services/mutation.service';
import  {createApolloFetch} from  'apollo-fetch'
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { createUploadLink } from "apollo-upload-client";
import { Location } from "@angular/common";

const key = environment.cryptoJsKey
const keyutf = cryptojs.enc.Utf8.parse(key)
const iv = cryptojs.enc.Utf8.parse(key)
const uri = environment.backendHost; // <-- add the URL of the GraphQL server here

@NgModule({
  imports: [
    HttpClientModule,
    ApolloModule,
    HttpLinkModule
  ]
})


export class GraphQLModule {
  deviceInfo: any
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  constructor(
    private apollo: Apollo,
    private cookieService: CookieService,
    private router: Router,
    public service: MutationService,
    private idle: Idle,
    private keepalive: Keepalive,
    public AuthService: AuthService,
    private data: DataService,
    private _location: Location
  ) {
      localStorage.setItem("lang",localStorage.getItem("lang"))
      this.data.authLoginServiceData({
        token: this.cookieService.get('x-access-token')
      });
      idle.setIdle(3600); //3600 1 hour
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      idle.setIdleName('localIdle')
      const timeOutSub = idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        if(this.idleState == 'Timed out!'){
          if(this.isIdleExceptionLogout()) {
            this.authenticateLogout()
          }
        }
        timeOutSub.unsubscribe()
      });
      idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
      idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!'
        console.log(this.idleState);
      });
      keepalive.interval(15);
      keepalive.onPing.subscribe(() => this.lastPing = new Date())    
      this.data.authLoginService.subscribe(data => {
        if (data) {
          idle.watch()
          this.timedOut = false;
        } else {
          idle.stop();
        }
      })
      const cache = new InMemoryCache({});
      const requestLink = new ApolloLink((operation, forward) => 
        new Observable(observer => {
          let handle: any;
          Promise.resolve(operation)
          .then(operation => {
            const accessToken = this.cookieService.get('x-access-token')
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
                lang : localStorage.getItem("lang"),
                ip : this.cookieService.get("x-lid"),
                userPreferredCurrency: localStorage.getItem("userPreferredCurrency"),
                tradePair: localStorage.getItem("tradePair")
              }
            });
          })
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            });
          })
          .catch(observer.error.bind(observer));

          return () => {
            if (handle) {
              handle.unsubscribe();
            }
          };
        }
      ));
      
      apollo.create({
        link: ApolloLink.from([
          new TokenRefreshLink({
            accessTokenField: "x-access-token",
            isTokenValidOrUndefined: () => {
              const token = this.cookieService.get("x-access-token")
              if (!token) return true 

              try {
                const decodedToken: any = jwtDecode(token), dd = decodedToken.dd, exp = decodedToken.exp, iat = decodedToken.iat
                //console.log(new Date(Date.now()) , new Date((exp * 1000) - 1700000))
                const cryptIp = cryptojs.AES.encrypt(dd.ip, keyutf, { iv: iv });
                const cryptoBroswer = cryptojs.AES.encrypt(dd.browser, keyutf, { iv: iv });
                //  if((cryptIp.toString() == localStorage.getItem("x-lid")) && (cryptoBroswer.toString() == localStorage.getItem("x-dd"))){
                //    if (((Date.now()) >= ((exp * 1000 )- 120000))) {
                if((cryptIp.toString() == this.cookieService.get("x-lid")) && (cryptoBroswer.toString() == this.cookieService.get("x-dd"))){
                  if (((Date.now()) >= ((exp * 1000 )- 120000))) { // 120000 is 2 min in milliseconds
                    if((cryptIp.toString() == this.cookieService.get("x-lid")) && (cryptoBroswer.toString() == this.cookieService.get("x-dd"))){
                      console.log("check refresh token")
                      return false;
                    } else{
                      this.getLogout()
                    }
                  }
                  else {
                    return true;
                  }
                } else{
                  this.getLogout()
                } 
              } catch {
                return false;
              }
            },
            
            fetchAccessToken:  async (): Promise<any> => {
              // const cryptoEmail = cryptojs.AES.encrypt(localStorage.getItem('registerMail'), keyutf, { iv: iv });
              const cryptoEmail = cryptojs.AES.encrypt(this.cookieService.get('registerMail'), keyutf, { iv: iv });
              const encryptedEMail = cryptoEmail.toString();
              let obj = {
                input: {
                  key : encryptedEMail
                }
              }
              
              const fetch = createApolloFetch({ uri });
              fetch.use(({ request, options }, next) => {
                if (!options.headers) {
                  options.headers = {};  // Create the headers object if needed.
                }
                // options.headers['ip'] = localStorage.getItem("x-lid")
                options.headers['ip'] = this.cookieService.get("x-lid")
                next();
              });

              const test = fetch({
                query: `mutation getRefreshToken($input: refreshTokenInput) {
                  getRefreshToken(refreshTokenData:$input) {
                    status_code
                    status_message
                    result {
                      token
                      email
                    }
                  }
                }`,
                variables: obj ,
              })
              return test
            },
            
            handleFetch: accessToken => {
              // localStorage.setItem('x-access-token',accessToken)
              this.cookieService.set('x-access-token',accessToken)
              // localStorage.setItem('x-lid',localStorage.getItem("x-lid"))
              // this.cookieService.set('x-lid',localStorage.getItem("x-lid"))
            },
            
            handleResponse: (operation, accessTokenField) => response => {
              if(response.data.getRefreshToken.status_code == 200){
                let responseData = response.data.getRefreshToken.result
                if(responseData){
                  // localStorage.setItem('x-access-token',responseData.token)
                  // localStorage.setItem('registerMail',responseData.email);

                  this.cookieService.set('x-access-token',responseData.token)
                  this.cookieService.set('registerMail',responseData.email);
                  return {
                    'x-access-token' :  responseData.token
                  }
                }else{
                  this.authenticateLogout()
                }
              } else{
                this.authenticateLogout()
              }
            },
            
            handleError: err => {
              console.warn("Your refresh token is invalid. Try to relogin");
              this.authenticateLogout()
            }
          }),
          
          onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
              for (let err of graphQLErrors) {
                // console.log(err.extensions.code)
                switch (err.extensions.code) {
                  case 'UNAUTHENTICATED':
                    this.getLogout()
                  break;
                  case 'INTERNAL_SERVER_ERROR':
                    // alert("INTERNAL_SERVER_ERROR! See console to check error")
                    //  this.getLogout()
                  break;
                }
              }
            }
          }),
          requestLink,
          // new HttpLink({
          //   uri: uri,
          //   credentials: "include",
          // }),
          new createUploadLink({uri: uri, credentials: "include"})
        ]),
        cache
      });
    }

  getLogout(){
    // localStorage.removeItem('registerMail')
    // localStorage.removeItem('x-access-token')
    // localStorage.removeItem('zodeak_tmp_user_str')
    this.resetTimeOut()
    this.cookieService.delete('registerMail');
    this.cookieService.delete('x-access-token');
    this.cookieService.delete('zodeak_tmp_user_str');
    this.data.authLoginServiceData("");
    this.router.navigate(['/login']);
  }

  authenticateLogout(){
    let obj = {
      input:{
        // token: localStorage.getItem("x-access-token"),
        token: this.cookieService.get("x-access-token"),
      }
    }
    // if(localStorage.getItem("x-access-token") != "") {
    if(this.cookieService.get("x-access-token") != "") {
      this.apollo.mutate({ mutation: DEVICE_LOGOUT, variables: obj })
      .subscribe((response: any) => {
        let responseData = response.data.deviceLogout
        if(responseData.status_code == 200){
           this.getLogout()
        } else{
           this.getLogout()
        }
      })
    } else{
      this.getLogout()
    }
  }

  // reset() {
  //   this.idle.watch();
  //   this.idleState = 'Started.';
  //   this.timedOut = false;
  // }

  resetTimeOut() {
    this.idle.stop();
    // this.idle.onIdleStart.unsubscribe();
    // this.idle.onTimeoutWarning.unsubscribe();
    // this.idle.onIdleEnd.unsubscribe();
  }

  isIdleExceptionLogout(): boolean {
    if(
      (
        this._location.path().split("/")[1] !== "markets" &&
        this._location.path().split("/")[1] !== "register" &&
        this._location.path().split("/")[1] !== "faq" &&
        this._location.path().split("/")[1] !== "terms-and-condition" &&
        this._location.path().split("/")[1] !== ""
      ) && this.cookieService.get("x-access-token")
    ) {
      return true
    } else {
      return false
    }
  }
}