import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router'
import {ThemePalette} from '@angular/material/core';

export interface UserData {
  coin: string;
  label: string;
  address: string;
  memo_tag: string;
  whitelist: string;
}

export interface Currency {
  value: string;
  viewValue: string;
}

export interface Coin {
  value: string;
  viewValue: string;
}

const USER_DATA: UserData[] = [
  {
    coin: 'btc',
    label: 'new label 1',
    address: '83RHF82TR4H8GH284TYH',
    memo_tag: '47856',
    whitelist: 'test 1'
  },
  {
    coin: 'xmr',
    label: 'new label 2',
    address: '83RHF82TR4H8GH284TYH',
    memo_tag: '49627',
    whitelist: 'test 2'
  },
  {
    coin: 'eth',
    label: 'new label 3',
    address: '83RHF82TR4H8GH284TYH',
    memo_tag: '19568',
    whitelist: 'test 3'
  },
  {
    coin: 'ltc',
    label: 'new label 4',
    address: '83RHF82TR4H8GH284TYH',
    memo_tag: '36954',
    whitelist: 'test 4'
  },
  {
    coin: 'btc',
    label: 'new label 5',
    address: 'MV93T493T34M340340',
    memo_tag: '47856',
    whitelist: 'test 5'
  },
  {
    coin: 'xrp',
    label: 'new label 6',
    address: 'MCF293UTT43J940GJ',
    memo_tag: '26358',
    whitelist: 'test 6'
  },
  {
    coin: 'btc',
    label: 'new label 7',
    address: '83RHF82TR4H8GH284TYH',
    memo_tag: '12368',
    whitelist: 'test 7'
  },
  {
    coin: 'btc',
    label: 'new label 8',
    address: '83RHF82TR4H8GH284TYH',
    memo_tag: '84596',
    whitelist: 'test 8'
  }
];

@Component({
  selector: 'app-address-management',
  templateUrl: './address-management.component.html',
  styleUrls: ['./address-management.component.scss']
})

export class AddressManagementComponent implements OnInit {
  displayedColumns: string[] = [
    'check',
    'coin',
    'label',
    'address',
    'memo_tag',
    'whitelist',
    'cancel'
  ];

  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;

  dataSource = new MatTableDataSource(USER_DATA);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  currencies: Currency[] = [
    { value: 'currency-0', viewValue: 'ETH/BTC' },
    { value: 'currency-1', viewValue: 'ETH/BTC' },
    { value: 'currency-2', viewValue: 'ETH/BTC' }
  ];

  selected = 'currency-0';

  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];

  constructor(public dialog: MatDialog) {}

   public ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public WhiteListPopup(): void {
    const dialogRef = this.dialog.open(WhiteListComponent, {
      width: '500px',
      disableClose: true,
    });
  
    const WhitelistSub = dialogRef.afterClosed().subscribe(result => {
      WhitelistSub.unsubscribe();
    });    
  }

  public WithdrawalPopup(): void {
    const dialogRef = this.dialog.open(WithdrawalComponent, {
      width: '560px',
      panelClass: 'withdraw-popup-wrap',
      disableClose: true,
    });
  
    const withdrawSub = dialogRef.afterClosed().subscribe(result => {
      withdrawSub.unsubscribe();
    });    
  }
}

@Component({
  selector: 'whitelist-popup',
  templateUrl: './whitelist-popup/whitelist-popup.component.html'
})

export class WhiteListComponent {
    constructor(
    public dialogRef: MatDialogRef<WhiteListComponent>,
    public router: Router,

    @Inject(MAT_DIALOG_DATA) 
    public dialog: MatDialog) {}

    closeDialog():void {
      this.dialogRef.close();
    }

    public GooglePopup() {
      document.getElementById("google-popup-btn").click();
      this.dialogRef.close();
    }
}

@Component({
  selector: 'withdrawal-popup',
  templateUrl: './withdrawal-popup/withdrawal-popup.component.html'
})

export class WithdrawalComponent {
    constructor(
    public dialogRef: MatDialogRef<WithdrawalComponent>,
    public router: Router,

    @Inject(MAT_DIALOG_DATA) 
    public dialog: MatDialog) {}

    Coins: Coin[] = [
      { value: 'coin-0', viewValue: 'LTC' },
      { value: 'coin-1', viewValue: 'XMR' },
      { value: 'coin-2', viewValue: 'BTC' },
      { value: 'coin-3', viewValue: 'ETH' }
    ];
  
    selected2 = 'coin-0';

    closeDialog():void {
      this.dialogRef.close();
    }
}