import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class MutationService {

  constructor(private apollo: Apollo) { }

  mutation<T>(obj: T, mutation): Observable<any> {
    return this.apollo.mutate({
      mutation: mutation,
      variables: { data: obj }
    })
      .catch(this.handleError);
  }

  imageUploadMutation(obj, query) {
    return this.apollo.mutate({
      mutation: query, variables: { data: obj },
      context: {
        useMultipart: true
      }
    }).catch(this.handleError);;
  }


  private handleError(error: any) {
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }
}
