import gpl from 'graphql-tag';

export const fetchAdvertiserPlans = gpl`
query getAdvertiserPlans($fetchAdvertiserData:getAdvertiserPlansInput) {
    getAdvertiserPlans(getAdvertiserPlansInput:$fetchAdvertiserData) {
  status_code
  status_message
  result{
      page
      pages
      total
      data {
          created_date
          modified_date
          planName
          planLevel
          userCurrentPlanLevel
          userPlanRejectedReason
          userPlanRequest
          benefits {
            adsPriority
            amountPerAdCurrency
            buyAdsLimit
            disputeLimit
            maxAmountPerAd
            minAmountPerAd
            pendingOrdersPerAd
            postBuyAds
            postSellAds
            sellAdsLimit
            totalPendingOrders
          }
        eligibility {
            kycVerification
            minAccountRegisteredDays
            minCompletedOrders
            minDepositAmount
            minDepositCurrency
            minOrderCompletionRate
            minTradeAmount
            minTradeCurrency
        }
      }
    }
  }
}
`

export const fetchEligibility = gpl`
query getEligibility($fetchEligibilityData:getEligibilityInput) {
  getEligibility(getEligibilityInput:$fetchEligibilityData) {
  status_code
  status_message
  result{
    accountRegisteredDays    
    accountRegisteredEligible    
    addEmail    
    completedOrders    
    completedOrdersEligible    
    completionRate    
    completionRateEligible    
    depositAmount    
    depositAmountEligible    
    depositCurrency    
    kycVerification    
    tradeAmount    
    tradeAmountEligible    
    tradeCurrency
    userCurrentPlanLevel
    userCurrentPlanName    
    userPlanRejectedReason    
    userPlanRequest
    }
  }
}
`