import { Component, OnInit,Inject } from '@angular/core';
import { faEuroSign, faSlash } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryService } from '../services/query.service';
import { headerSiteSettings,DEVICE_LOGOUT, UPDATE_USER_LANG } from './types/header.query.types';
import { siteSettingsResponse } from './types/header.output.types';
import { HeaderService } from '../services/header.service';
import { Apollo } from 'apollo-angular';
import { DataService } from '../services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { GETFIATCURRENCY } from '../wallet/fiat-deposit/types/fiatdeposit.query.types';
import { environment } from "../../environments/environment";
import { BinancestreamService } from '../services/binancestream.service';
import { GET_USER_BY_JWT } from '../security/identity-verification/types/kyc.query.types';
import { fetchEligibility } from '../vip/types/super-advertiser.query';

export interface Currency {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ ]
})

export class HeaderComponent implements OnInit {
  faEuroSign = faEuroSign;
  username: String = '';
  mail: String = "";
  fiatCurrency: String = "";
  cryptoCurrency: String = "";
  site_logo: String = "";
  idleState: String
  selectedLanguage: string;
  checked = false;
  modeImg = 'dark_mode'
  attr = document.documentElement;
  templogo="black-logo.png";
  modeClass=false
  buildCheck: Boolean 
  themeColor:any;
  siteName: string;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    public translate: TranslateService,
    private route: ActivatedRoute,
    public auth: AuthService,
    private cookieService: CookieService,
    private QueryService: QueryService,
    private binanceStreamService: BinancestreamService,
    public headerService: HeaderService,
    private apollo: Apollo,    
    private data: DataService,    
  ) {
    this.siteName = this.cookieService.get("siteName")
    localStorage.getItem("mode") ? null : localStorage.setItem("mode",'light')
    localStorage.getItem("mode") ? null : this.attr.setAttribute('data-theme' , 'light');
    this.themeColor = localStorage.getItem("mode");

    this.buildCheck = environment.live    
    let url   = this.router
    let email = this.headerService.mailTemp
    if (email != null || email != undefined) {
      let splitted = email.split("@");
      this.username = splitted[0].substring(0, 2)
      this.mail = this.username + "***@" + splitted[1];
    }
    this.getFiatCurrency();
  }

  fiatCurrencies: Array<any> = []
  getFiatCurrency() {
    this.QueryService.query(GETFIATCURRENCY, null, 'network-only').subscribe(res => {
      let response = res.data.getFiatCurrency
      if (response.status_code == 200) {
        this.fiatCurrencies = response.result
      }
    })
  }

  themeLocalStorage(){
   let mode=localStorage.getItem("mode");
   if(mode == 'dark'){
    this.attr.setAttribute('data-theme' , 'dark');
    this.checked=true
    this.modeImg='wb_sunny';
    this.templogo="white-logo.png"
   }
   else{
    this.attr.setAttribute('data-theme' , 'light');
    this.checked=false
    this.modeImg='dark_mode';
    this.templogo="black-logo.png";
   }
  }
  colorScheme(event:any){  
    if(event.checked){
    this.modeClass=true;
      this.modeImg='wb_sunny';
      localStorage.setItem("mode",'dark')
      this.attr.setAttribute('data-theme' , 'dark');
      this.templogo="white-logo.png";
      this.headerService.Captchatheme.next("dark");
      this.data.chartTheme.next("dark");
    }
    else{
      this.modeClass=false;
      this.modeImg='dark_mode';
      localStorage.setItem("mode",'light')
      this.attr.setAttribute('data-theme' , 'light');
      this.templogo="black-logo.png";
      this.headerService.Captchatheme.next("light");
      this.data.chartTheme.next("light");
    }
    this.themeColor = localStorage.getItem("mode");
  }

  imageClick(data){  
      localStorage.setItem("lang",data)
      this.translate.use(data)
      let obj = {
        input:{
          user_lang: localStorage.getItem("lang")
        }
      }
      
      const UPDATE_USER_LANGQuery =  this.apollo.mutate({ mutation: UPDATE_USER_LANG, variables: obj })
      .subscribe((response: any) => {        
        this._document.defaultView.location.reload();
          UPDATE_USER_LANGQuery.unsubscribe();
      })      
      
      // this.attr.setAttribute('lang' , localStorage.getItem("lang"));

  }

  logout() {
    let obj = {
      input:{
        // token: localStorage.getItem("x-access-token"),
        token: this.cookieService.get("x-access-token"),
      }
    }
    this.apollo.mutate({ mutation: DEVICE_LOGOUT, variables: obj })
    .subscribe((response: any) => {
      let responseData = response.data.deviceLogout
      if(responseData.status_code == 200){
        this.cookieService.delete('registerMail')
        this.cookieService.delete('x-access-token')
        this.cookieService.delete('zodeak_tmp_user_str')
        this.data.authLoginServiceData("");
        this.router.navigate(['/login']);
      }else{
        this.cookieService.delete('registerMail')
        this.cookieService.delete('x-access-token')
        this.cookieService.delete('zodeak_tmp_user_str')

        this.data.authLoginServiceData("");
        this.router.navigate(['/login']);
      }
    })   
  }

  label: string = "USD"
  ngOnInit() {
    this.themeLocalStorage();
    this.selectedLanguage=localStorage.getItem("lang")    

    let email = this.headerService.mailTemp
    if (email != null || email != undefined) {
      let splitted = email.split("@");
      this.username = splitted[0].substring(0, 2)
      this.mail = this.username + "***@" + splitted[1];
    }

    this.QueryService.query(headerSiteSettings, null, 'network-only').subscribe(data => {
      let response = data.data.getDefaultCurrency as siteSettingsResponse
      this.fiatCurrency = response.result.fiat_default_currency
      this.cryptoCurrency = response.result.crypto_default_currency
      this.site_logo = response.result.site_logo
    })

    let userPreferredCurrency = localStorage.getItem('userPreferredCurrency');
    if(userPreferredCurrency){
      this.label = userPreferredCurrency
    } else {
      this.label = "USD"
      localStorage.setItem('userPreferredCurrency', this.label);
    }

    this.userDetails();
  }

  images: any;

  userDetails() {
    this.QueryService.query(GET_USER_BY_JWT, null, 'network-only').subscribe(res => {      
      if(res.data.getUserByJWT.result) {
        this.images = res.data.getUserByJWT.result.profile_image
      }      
    })
  }

  ngDoCheck() { 
    if (this.cookieService.get("login") === "true") {
      this.getUserVIPPlanName();
      let email = this.headerService.mailTemp
      if (email != null || email != undefined) {
        let splitted = email.split("@");
        this.username = splitted[0].substring(0, 2)
        this.mail = this.username + "***@" + splitted[1];
      }      
      this.cookieService.set("login", "false")
    }
  }

  navigateCryptoCurrency(type) {
    if (type == 'deposit') {
      this.router.navigate(['/wallet/crypto-deposit/' + this.cryptoCurrency]);
    } else if (type == 'withdraw') {
      this.router.navigate(['/wallet/crypto-withdraw/' + this.cryptoCurrency]);
    }
  }

  navigateFiatCurrency(type) {
    if (type == 'deposit') {
      this.router.navigate(['/wallet/fiat-deposit/' + this.fiatCurrency]);
    } else if (type == 'withdraw') {
      this.router.navigate(['/wallet/fiat-withdraw/' + this.fiatCurrency]);
    }
  }

  setUserPrefCurrency(currency) {
    this.label = currency
    localStorage.setItem("userPreferredCurrency", currency)    
    window.location.reload()
  }

  userCurrentVIPPlanName:any;
  getUserVIPPlanName() {
    const fetchEligibilityData = {
      fetchEligibilityData: {
        planLevel: 'rank2' 
      }
    }
    const fetchEligibilityPlansQuery = this.QueryService.query(fetchEligibility, fetchEligibilityData, 'network-only').subscribe(res => {
      if(res.data.getEligibility && res.data.getEligibility.status_code == 200){
        let response = res.data.getEligibility.result
        this.userCurrentVIPPlanName = response.userCurrentPlanName
      } else {
        this.userCurrentVIPPlanName = undefined;
      }
      fetchEligibilityPlansQuery.unsubscribe();
    })
  }
}
