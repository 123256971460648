import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service'
// import { BehaviorSubject } from 'rxjs';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // siteloader          = new BehaviorSubject(false);
  // loaderCurrentStatus = this.siteloader.asObservable();

  constructor(private myRoute       : Router, 
              private cookieService : CookieService,
              private snackBar      : MatSnackBar) { }

  isLoggedIn() {
    // let token = localStorage.getItem("x-access-token")
    let token = this.cookieService.get("x-access-token")
    if(token != '' && token !== null){
      return true;
    }else{
      return false;
    }
  }
  
  logout() {
    // localStorage.removeItem('registerMail')
    // localStorage.removeItem('x-access-token')
    // localStorage.removeItem('zodeak_tmp_user_str')

    this.cookieService.delete('registerMail')
    this.cookieService.delete('x-access-token')
    this.cookieService.delete('zodeak_tmp_user_str')
    this.myRoute.navigate(["/login"]);
  }

  siteLoader(message: boolean) {
    // this.siteloader.next(message)
  }

  openSnackBar(message: string, className: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: [className],
      duration: 6 * 1000
    });
  }
}