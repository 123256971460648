import { Component, OnInit } from '@angular/core';
import { GET_DEFAULT_CURRENCY } from 'src/app/header/types/header.query.types';
import { siteSettingsResponse } from 'src/app/header/types/header.output.types';
import { QueryService } from '../../services/query.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent implements OnInit {
  fiatCurrency: String = "";
  cryptoCurrency: String = "";
  crypto_deposit: boolean;
  crypto_withdraw: boolean;
  fiat_deposit: boolean;
  fiat_withdraw: boolean;

  constructor(
    private router: Router, 
    private QueryService: QueryService,
    private route : ActivatedRoute,
    ) { }

  ngOnInit() {
    let url = this.route.snapshot['_routerState'].url.split('/');
    this.tabIndexChange(url[1]);
    this.orderTabIndexChange(url[1]);
    const GET_DEFAULT_CURRENCYQuery = this.QueryService.query(GET_DEFAULT_CURRENCY, null, 'network-only').subscribe(data => {
      let responseData = data.data.getDefaultCurrency;
      if(responseData.status_code === 200) {
        let response = data.data.getDefaultCurrency as siteSettingsResponse
        this.fiatCurrency = response.result.fiat_default_currency
        this.cryptoCurrency = response.result.crypto_default_currency
      }
      GET_DEFAULT_CURRENCYQuery.unsubscribe();
    })
    this.moduleactiveClass();
  }


  navigateCryptoCurrency(type) {
    // console.log(this.cryptoCurrency);
    // if(this.cryptoCurrency) {
    //   if (type == 'deposit') {
    //     this.router.navigate(['/wallet/crypto-deposit/' + this.cryptoCurrency]);
    //   } else if (type == 'withdraw') {
    //     this.router.navigate(['/wallet/crypto-withdraw/' + this.cryptoCurrency]);
    //   }
    // }

    const GET_DEFAULT_CURRENCYQuery = this.QueryService.query(GET_DEFAULT_CURRENCY, null, 'network-only').subscribe(data => {
      let responseData = data.data.getDefaultCurrency;
      if(responseData.status_code === 200) {
        let response = data.data.getDefaultCurrency as siteSettingsResponse
        this.fiatCurrency = response.result.fiat_default_currency
        this.cryptoCurrency = response.result.crypto_default_currency
        if(this.cryptoCurrency) {
          if (type == 'deposit') {
            this.router.navigate(['/wallet/crypto-deposit/' + this.cryptoCurrency]);
          } else if (type == 'withdraw') {
            this.router.navigate(['/wallet/crypto-withdraw/' + this.cryptoCurrency]);
          }
        }
      }
      GET_DEFAULT_CURRENCYQuery.unsubscribe();
    })
  }

  navigateFiatCurrency(type) {
    // console.log(this.fiatCurrency);
    // if(this.fiatCurrency) {
    //   if (type == 'deposit') {
    //     this.router.navigate(['/wallet/fiat-deposit/' + this.fiatCurrency]);
    //   } else if (type == 'withdraw') {
    //     this.router.navigate(['/wallet/fiat-withdraw/' + this.fiatCurrency]);
    //   }
    // }
    const GET_DEFAULT_CURRENCYQuery = this.QueryService.query(GET_DEFAULT_CURRENCY, null, 'network-only').subscribe(data => {
      let responseData = data.data.getDefaultCurrency;
      if(responseData.status_code === 200) {
        let response = data.data.getDefaultCurrency as siteSettingsResponse
        this.fiatCurrency = response.result.fiat_default_currency
        this.cryptoCurrency = response.result.crypto_default_currency

        if(this.fiatCurrency) {
          if (type == 'deposit') {
            this.router.navigate(['/wallet/fiat-deposit/' + this.fiatCurrency]);
          } else if (type == 'withdraw') {
            this.router.navigate(['/wallet/fiat-withdraw/' + this.fiatCurrency]);
          }
        }
      }
      GET_DEFAULT_CURRENCYQuery.unsubscribe();
    })
  }

  moduleactiveClass(){
    let crypto_deposit=this.router.url.includes('/wallet/crypto-deposit/');
    let crypto_withdraw=this.router.url.includes('/wallet/crypto-withdraw/');
    let fiat_deposit=this.router.url.includes('/wallet/fiat-deposit/');
    let fiat_withdraw=this.router.url.includes('/wallet/fiat-withdraw/');
  
    if(crypto_deposit){
        this.crypto_deposit=true;
    }
    else if(crypto_withdraw) {
      this.crypto_withdraw=true;
      }
    else if(fiat_deposit) {
      this.fiat_deposit=true;
      }
    else if(fiat_withdraw) {
      this.fiat_withdraw=true;
      }
  }

  tabValue: any;
  orderTabs: any;
  tabIndex = 0
  tabOrderIndex = 0

  tabIndexChange(data: any) {
    this.tabIndex = 0
  }

  orderTabIndexChange(data: any) {
    this.tabOrderIndex = 0  
  }

}
