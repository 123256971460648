import gpl from 'graphql-tag';

export const FETCH_PAYMENT_METHODS = gpl`query fetchPaymentMethods{
    fetchPaymentMethods{
    status_code
    status_message
    result{
      status
      name
      _id
      type
      payment_fields
    }
  }
}
`


export const P2P_CURRENCY = gpl`
 query fetchP2PCurrency($fetchP2PCurrencyData:fetchP2PCurrencyInput){
  fetchP2PCurrency(fetchP2PCurrencyInput: $fetchP2PCurrencyData){
    status_code
    result{
      image
      type
      code
      status
      p2p_status
      min_ad_limit
      max_ad_limit
      p2p_maker_fee
    }
  }
}
`

export const CMS_Data = gpl`
 query fetchCMS($cmcData: fetchCMSInput){
  fetchCMS(fetchCMSData: $cmcData){
    status_code
    result{
      _id
      created_date
      footer
      modified_date
      status
      type
      url
      lang {
        content
        lang_code
        name
      }
    }
  }
}
`