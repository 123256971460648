import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  Captchatheme: Subject<string> = new Subject();

  // mailTemp = localStorage.getItem('registerMail')
  mailTemp = this.cookieService.get('registerMail')

  constructor(public cookieService: CookieService) { }

 
}
