import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { routes } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent, GooglePopupComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatDatepickerModule,
} from '@angular/material';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { ApolloModule } from "apollo-angular";
import { HttpLinkModule } from "apollo-angular-link-http";
import { MAT_SNACK_BAR_DATA } from '@angular/material';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { DataService } from './services/data.service'
import { WhiteListComponent, WithdrawalComponent } from './security/address-management/address-management.component';
import { AuthService } from './services/auth.service'
import { ModifyAntiPhishingComponent } from './security/anti-phishing-code/anti-phishing-code.component';
import { CreateAntiPhishingComponent } from './security/anti-phishing-code/anti-phishing-code.component'
import { SocketService } from './services/socket.service';
import { NgApexchartsModule } from "ng-apexcharts";
import { CookieService } from 'ngx-cookie-service';
import { SharedModule } from './shared/shared.module'
import { DeviceDetectorService } from 'ngx-device-detector';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { TranslateModule, TranslateLoader }  from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NgxPayPalModule } from 'ngx-paypal';
import { BinancestreamService } from './services/binancestream.service';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxPayPalModule
  ],
  declarations: [],
  imports: [GraphQLModule, HttpClientModule,MomentModule,
    NgIdleKeepaliveModule.forRoot()],
    providers:[]
})
export class MaterialModule { }

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    GooglePopupComponent,
    ModifyAntiPhishingComponent,
    CreateAntiPhishingComponent,
    SnackbarComponent,
    WhiteListComponent,
    WithdrawalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    FontAwesomeModule,
    NgApexchartsModule,
    SharedModule,
    RouterModule.forRoot(routes, {
      useHash: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      
    })
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  exports: [
    GooglePopupComponent, 
    WhiteListComponent, 
    WithdrawalComponent, 
    ModifyAntiPhishingComponent,
    CreateAntiPhishingComponent, 
  ],
  entryComponents: [
    GooglePopupComponent, 
    SnackbarComponent, 
    WhiteListComponent, 
    WithdrawalComponent, 
    ModifyAntiPhishingComponent,
    CreateAntiPhishingComponent
  ],
  providers: [
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    DataService,
    AuthService,
    CookieService,
    SocketService,    
    BinancestreamService,
    DeviceDetectorService
  ],
})
export class AppModule { }


// AoT requires an exported function for factories
// export function HttpLoaderFactory(httpClient: HttpClient) {
//   return new TranslateHttpLoader(httpClient);
// }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
