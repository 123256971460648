import { Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service'
import { SiteMaintenanceService } from './services/site-maintenance.service';

export const routes: Routes = [
  {
    path: '',
    loadChildren: './home/home.module#HomeModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'register',
    loadChildren: './register/register.module#RegisterModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [SiteMaintenanceService, AuthGuardService]
  },
  {
    path: 'wallet',
    loadChildren: './wallet/wallet.module#WalletModule',
    canActivate: [SiteMaintenanceService, AuthGuardService]
  },
  {
    path: 'p2p',
    loadChildren: './p2p/p2p.module#P2pModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'P2P',
    loadChildren: './vip/vip.module#VIPModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'footer-content-view',
    loadChildren: './dynamic-footer-content/dynamic-footer-content.module#DynamicFooterContentModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'security',
    loadChildren: './security/security.module#SecurityModule',
    canActivate: [SiteMaintenanceService, AuthGuardService]
  },
  {
    path: 'settings',
    loadChildren: './settings/settings.module#SettingsModule',
    canActivate: [SiteMaintenanceService, AuthGuardService]
  },
  {
    path: 'customer-support',
    loadChildren: './customer-support/customer-support.module#CustomerSupportModule',
    canActivate: [SiteMaintenanceService, AuthGuardService]
  },
  {
    path: 'notification',
    loadChildren: './notification/notification.module#NotificationModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'email-verification/:id',
    loadChildren: './email-verification/email-verification.module#EmailVerificationModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'disable-account',
    loadChildren: './disable-account/disable-account.module#DisableAccountModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'delete-account',
    loadChildren: './delete-account/delete-account.module#DeleteAccountModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'reactivate-account/:id',
    loadChildren: './reactivate-account/reactivate-account.module#ReactivateAccountModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'reset-google-tfa',
    loadChildren: './reset-google/reset-google.module#ResetGoogleModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'change-email-address',
    loadChildren: './change-email-address/change-email-address.module#ChangeEmailAddressModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'verify-email-address',
    loadChildren: './verify-email-address/verify-email-address.module#VerifyEmailAddressModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'reset-password',
    loadChildren: './reset-password/reset-password.module#ResetPasswordModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path: 'help',
    loadChildren: './help/help.module#HelpModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path:'404',
    loadChildren: './not-found/not-found.module#NotFoundModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path : 'sample',
    loadChildren: './sample/sample.module#SampleModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path : 'about',
    loadChildren: './about/about.module#AboutModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path : 'privacy',
    loadChildren: './privacy/privacy.module#PrivacyModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path : 'contact-us',
    loadChildren: './contact-us/contact-us.module#ContactUsModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path : 'faq',
    loadChildren: './faq/faq.module#FaqModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path : 'terms-and-condition',
    loadChildren: './terms-and-condition/terms-and-condition.module#TermsAndConditionModule',
    canActivate: [SiteMaintenanceService]
  },
  {
    path : 'site-maintenence',
    loadChildren: './site-maintenance/site-maintenance.module#SiteMaintenanceModule'
  },
  {
    path: "**",
    redirectTo: "404"
  }
];