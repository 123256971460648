import { Injectable } from '@angular/core';
import async from "async";
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BinancestreamService {
  pairObservableData      = new BehaviorSubject(false);
  getCurrentPairData      = this.pairObservableData.asObservable();
  themeChangeObservableData  = new BehaviorSubject(false);
  getCurrentColorTheme       = this.themeChangeObservableData.asObservable();

  combinedAllStreams:any  = []
  pairsAllStreams:any     = [];  
  homePairs:any           = [];  
  combinedStream:any      = {};
  homePairTickerStream:any= {};
  binanceStreamURL:string = environment.binanceSocket;  
  marketPagePairTickerStream:any    = {streamList:[]};
  pairTickerStream:any    = {streamList:[]};
  getCurrentPairKlineStream:any ={streamList:[]};

  constructor() { }

  getCombineStreams(pair:string){
    return new Promise(async(resolve,reject)=>{
      try {          
          pair                            = pair.toLowerCase();
          let tickerStream:string         = pair+"@ticker";
          // let klineStream:string       = pair+"@kline_1d";
          let openOrderStream:string      = pair+"@depth";
          let tradeHistoryStream:string   = pair+"@trade";
  
          let socketURL:string            = environment.binanceSocket+tickerStream+'/'+openOrderStream+"/"+tradeHistoryStream;                      
          let binanceSocket               = new WebSocket(socketURL)	  
          let finalData                   = {binanceSocket : binanceSocket,tickerStream : tickerStream ,openOrderStream:openOrderStream,tradeHistoryStream:tradeHistoryStream,streamList : [tickerStream,openOrderStream,tradeHistoryStream]}
          this.combinedStream             = finalData;   
          this.combinedAllStreams.push(this.combinedStream);
          resolve(finalData)  
      } catch (error) {
        console.log("getCombined stream catch err",error);
        resolve(false);
      } 
    })
  }  

  getTicker(pairList:any){    
    return new Promise(async(resolve,reject)=>{
      let tickerStream:string;
      let allPairStream = [];         
      async.eachOfSeries(pairList,(value:any,index:any,cb:any)=>{        
        const element     = value;        
        let { symbol }    = element
        if( symbol ){
          let lowerCasePair = symbol.toLowerCase()
          let modifiedPair  = lowerCasePair.replace("/", "")      
          let socPair:string;
          if(index == 0 ){
            socPair           = modifiedPair+"@ticker/"
            tickerStream      = socPair;    
          }
          else if(index+1 == pairList.length ){
            socPair           = modifiedPair+"@ticker"
            tickerStream      = tickerStream+socPair;        
          }
          else{
            socPair           = modifiedPair+"@ticker/"
            tickerStream      = tickerStream+socPair;        
          }
          allPairStream.push(modifiedPair+"@ticker")
          cb();
        }
        else{
          cb();
        }
      },(data)=>{        
        tickerStream      = environment.binanceSocket+tickerStream;        
        let socket:any    = new WebSocket(tickerStream)	      
        let finalData:any = {socket:socket,streamList:allPairStream}
        this.pairTickerStream  = finalData;
        this.pairsAllStreams.push(this.pairTickerStream)
        resolve(finalData);        
      })      
    });
  }

  getHomePageTicker(pairList:any){    
    return new Promise(async(resolve,reject)=>{
      let tickerStream:string;
      let klineStream:string;
      let allPairStream = [];   
      let allKlinePairStream = [];     
          
      async.eachOfSeries(pairList,(value:any,index:any,cb:any)=>{        
        const element     = value;        
        let { symbol }    = element
        if( symbol ){
          let lowerCasePair = symbol.toLowerCase()
          let modifiedPair  = lowerCasePair.replace("/", "")      
          let socPair:string;
          let klinePair:string;
          if(index == 0 ){
            socPair           = modifiedPair+"@ticker/"
            tickerStream      = socPair;    

            // klinePair         = modifiedPair+"@kline_8h/"
            // klineStream       = klinePair;
          }          
          else if(index+1 == pairList.length ){
            socPair           = modifiedPair+"@ticker"
            tickerStream      = tickerStream+socPair;   
            
            // klinePair         = modifiedPair+"@kline_8h"
            // klineStream       = klineStream+klinePair;

          }
          else{
            socPair           = modifiedPair+"@ticker/"
            tickerStream      = tickerStream+socPair;   
            
            // klinePair         = modifiedPair+"@kline_8h/"
            // klineStream       = klineStream+klinePair;
          }
          
          allPairStream.push(modifiedPair+"@ticker")
          allKlinePairStream.push(modifiedPair+"@kline_8h")
          cb();
        }
        else{
          cb();
        }
      },(data)=>{        
        tickerStream         = environment.binanceSocket+tickerStream;        
        // klineStream          = environment.binanceSocket+klineStream;        
        let socket:any       = new WebSocket(tickerStream)	      
        // let klineSocket:any  = new WebSocket(klineStream)	      
        let finalData:any    = {socket:socket,streamList:allPairStream}
        this.homePairTickerStream  = finalData;
        this.homePairs.push(this.homePairTickerStream)
        resolve(finalData);        
      })      
    });
  }

  getMarketPagePairTicker(pairList:any){   
    return new Promise(async(resolve,reject)=>{
      let tickerStream:string;
      let allPairStream = [];         
      async.eachOfSeries(pairList,(value:any,index:any,cb:any)=>{        
        const element     = value;        
        let { symbol }    = element
        
        if( symbol ){
          let lowerCasePair = symbol.toLowerCase()
          let modifiedPair  = lowerCasePair.replace("/", "")      
          let socPair:string;
          if(index == 0 ){
            socPair           = modifiedPair+"@ticker/"
            tickerStream      = socPair;    
          }          
          else if(index+1 == pairList.length ){
            socPair           = modifiedPair+"@ticker"
            tickerStream      = tickerStream+socPair;        
          }
          else{
            socPair           = modifiedPair+"@ticker/"
            tickerStream      = tickerStream+socPair;        
          }
          
          allPairStream.push(modifiedPair+"@ticker")
          
          cb();
        }
        else{
          cb();
        }
      },(data)=>{        
        tickerStream      = environment.binanceSocket+tickerStream;        
        let socket:any    = new WebSocket(tickerStream)	      
        let finalData:any = {socket:socket,streamList:allPairStream}
        this.marketPagePairTickerStream  = finalData;  
              
        resolve(finalData);        
      })      
    });
  }

  disconnectMarketPageBinanceTicker(){  
    return new Promise((resolve,reject)=>{
      try {
        if( this.marketPagePairTickerStream && this.marketPagePairTickerStream.streamList && this.marketPagePairTickerStream.streamList.length > 0 ){
          // this.marketPagePairTickerStream.socket.close()
          // this.marketPagePairTickerStream.socket.onclose = (event) => {        
          // };
           let data = {
            "method": "UNSUBSCRIBE",
            "params":this.marketPagePairTickerStream.streamList,
            "id"    : 312
          }
          if(this.marketPagePairTickerStream.socket && typeof this.marketPagePairTickerStream.socket != "undefined"){
            this.marketPagePairTickerStream.socket.send(data)
          }
          console.log('Market page Binance Ticker stream connection has been closed successfully');
          this.marketPagePairTickerStream = {streamList:[]};
          resolve(true)
        }
        else{
          resolve(true)              
        }
      } catch (error) {
        console.log('disconnectBinanceTicker catch',error);
        resolve(true)
      }
    })
  }

  disconnectBinanceHomePairTicker(){  
    return new Promise((resolve,reject)=>{
      try {
        if( this.homePairTickerStream && this.homePairTickerStream.streamList && this.homePairTickerStream.streamList.length > 0 ){
          this.homePairTickerStream.socket.close()
          // this.homePairTickerStream.klineSocket.close()
          this.homePairTickerStream.socket.onclose = (event) => {        
            console.log('Binance home/market page pair Ticker stream connection has been closed successfully');
              this.homePairTickerStream = {streamList:[],klineStreamList:[]};
            resolve(true)
          };
          // this.homePairTickerStream.klineSocket.onclose = (event) => {        
          // }
        }
        else{
          resolve(true)              
        }
      } catch (error) {
        console.log('disconnectBinanceTicker catch',error);
        resolve(true)
      }
    })
  }

  getCurrentKlineStream(pair:string,duration="1d"){
    return new Promise((resolve,reject)=>{
      try {        
        pair                            = pair.toLowerCase();      
        let klineStream:string          = pair+"@kline_"+duration;     
        let socketURL:string            = environment.binanceSocket+klineStream;                      
        let binanceSocket               = new WebSocket(socketURL)	  
        let finalData                   = {binanceSocket : binanceSocket,klineStream : klineStream,streamList : [klineStream]}
        this.getCurrentPairKlineStream  = finalData;   
        resolve(finalData)        
      } catch (error) {
        console.log('getCurrentKlineStream catch error',error)
        resolve(false)        
      }
    })
  }

  disconnectCurrentsBinanceKlineStream(){  
    return new Promise((resolve,reject)=>{
      try {
        if( this.getCurrentPairKlineStream && this.getCurrentPairKlineStream.streamList && this.getCurrentPairKlineStream.streamList.length > 0 ){
          let data = {
            "method": "UNSUBSCRIBE",
            "params":this.getCurrentPairKlineStream.streamList,
            "id"    : 312
          }
          if(this.getCurrentPairKlineStream.binanceSocket && typeof this.getCurrentPairKlineStream.binanceSocket != "undefined"){            
            this.getCurrentPairKlineStream.binanceSocket.send(data)
          }
          this.getCurrentPairKlineStream.binanceSocket.onclose = (event) => {        
            console.log('Binance current kline chart stream connection has been closed successfully');
            this.getCurrentPairKlineStream = {streamList:[]};
            resolve(true)
          };
        }
        // if( this.getCurrentPairKlineStream && this.getCurrentPairKlineStream.streamList && this.getCurrentPairKlineStream.streamList.length > 0 ){
        //   if(this.getCurrentPairKlineStream.binanceSocket && typeof this.getCurrentPairKlineStream.binanceSocket != "undefined"){
        //     this.getCurrentPairKlineStream.binanceSocket.close()
        //     this.getCurrentPairKlineStream.binanceSocket.onclose = (event) => {        
        //       console.log('Binance current kline chart stream connection has been closed successfully');
        //       this.getCurrentPairKlineStream = {streamList:[]};
        //       resolve(true)
        //     };
        //   }
        //   else{
        //     console.log('kline current stream not available')
        //     resolve(true)
        //   }
        // }
        else{
          resolve(true)
        }              
      } catch (error) {
        console.log('disconnectCurrentsBinanceKlineStream catch',error);
        resolve(true)
      }
    })
  }

  disconnectCombinedStreams(pair){   
    return new Promise((resolve,reject)=>{
      try {
        console.log('combinedAllStreams',this.combinedAllStreams)
       if( this.combinedAllStreams.length > 0 ){
        async.eachOfSeries(this.combinedAllStreams,(value,index,cb)=>{
          console.log('combined stream value',value);
          let data = {
            "method": "UNSUBSCRIBE",
            "params": value.streamList,
            "id": 312
          }
          if(value.binanceSocket && typeof value.binanceSocket != "undefined"){
            value.binanceSocket.send(data)
            // value.binanceSocket.onclose = (event) => {                      
            // };
            cb();
          }
          else{
            cb();
          }
        },(data)=>{
          console.log('Binance combined all stream connection has been closed successfully');
          this.combinedAllStreams = [];
          this.combinedStream     = {streamList:[]}
          resolve(true)
        })
       }
      // if( this.combinedStream ){
      //   this.combinedStream.binanceSocket.close()
      //     this.combinedStream.binanceSocket.onclose = (event) => {        
      //       console.log('Binance combined all stream connection has been closed successfully');
      //       this.combinedStream = {streamList:[]};
      //       resolve(true)
      //     };
      // }
       else{
        resolve(true)
       }           
      } catch (error) {
        console.log("disconnectCombinedStreams catch",error)
        resolve(true)
      }
    })
  }

  disconnectBinanceTicker(){  
    return new Promise((resolve,reject)=>{
      try {
        if( this.pairTickerStream && this.pairTickerStream.streamList && this.pairTickerStream.streamList.length > 0 ){
          // this.pairTickerStream.socket.close()
          let data = {
            "method": "UNSUBSCRIBE",
            "params":this.pairTickerStream.streamList,
            "id"    : 312
          }
          if(this.pairTickerStream.socket && typeof this.pairTickerStream.socket != "undefined"){
            this.pairTickerStream.socket.send(data)
          }
          // this.pairTickerStream.socket.onclose = (event) => {        
          // };
          console.log('Binance Ticker stream connection has been closed successfully');
          this.pairTickerStream = {streamList:[]};
          resolve(true)
        }
        else{
          resolve(true)              
        }
      } catch (error) {
        console.log('disconnectBinanceTicker catch',error);
        resolve(true)
      }
    })
  }

  passCurrentPair(message: any) {
    this.pairObservableData.next(message)
  }
  passCurrentTheme(message: any) {
    this.themeChangeObservableData.next(message)
  }
}
