import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router'
import { GET_USER_BY_JWT } from './types/anti-phishing-code.query'
import { QueryService } from '../../services/query.service'
import { MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { AntiPhishingInput } from './types/anti-phishing-code.input';
import { ANTI_PHISHING_CODE } from './types/anti-phishing-code.mutation';
import { personalinforesponse } from './types/anti-phishing-code.output';
import { MutationService } from '../../services/mutation.service';
import { DataService } from '../../services/data.service';
import { GET_DEFAULT_CURRENCY } from 'src/app/header/types/header.query.types';
import { siteSettingsResponse } from 'src/app/header/types/header.output.types';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-anti-phishing-code',
  templateUrl: './anti-phishing-code.component.html',
  styleUrls: ['./anti-phishing-code.component.scss']
})

export class AntiPhishingCodeComponent implements OnInit {
  constructor(public dialog: MatDialog, public snackBar: MatSnackBar, public QueryService: QueryService, private service: MutationService, public translate: TranslateService, private router: Router) {
    const GET_USER_BY_JWTQuery = this.QueryService.query(GET_USER_BY_JWT, null, 'network-only').subscribe(res => {
      let response = res.data.getUserByJWT
      if (response.result.anti_phishing_code == "" && response.result.tfa_status == "unverified") {
        this.createCode = true
        this.openSnackBar(this.translate.instant("snackbar.enableTFA"), 'red-snackbar');
        this.router.navigate(['/security'])
      }else if (response.result.anti_phishing_code != "" && response.result.tfa_status == "unverified") {
        this.createCode = true
        this.openSnackBar(this.translate.instant("snackbar.enableTFA"), 'red-snackbar');
        this.router.navigate(['/security'])
      }else if (response.result.anti_phishing_code == "" && response.result.tfa_status == "verified") {
        this.createCode = true
      }else {
        let antiphishingcode = response.result.anti_phishing_code
        this.code = antiphishingcode.substring(0, 2) + "***"
        this.createCode = false
      }
      GET_USER_BY_JWTQuery.unsubscribe();
    })

    this.QueryService.query(GET_DEFAULT_CURRENCY, null, 'network-only').subscribe(data => {
      let response = data.data.getDefaultCurrency as siteSettingsResponse
      this.siteName = response.result.site_name
    })
   }
  createCode: boolean = false;
  code: String;
  siteName: string = ""

  ngOnInit() {
  }

  public ModifyAntiPhishingPopup(): void {
    const dialogRef = this.dialog.open(ModifyAntiPhishingComponent, {
      width: '500px',
      disableClose: true
    });

    const antiPhishingSub = dialogRef.afterClosed().subscribe(result => {
      antiPhishingSub.unsubscribe();
    });
  }

  public CreateAntiPhishingPopup(): void {
    const dialogRef = this.dialog.open(CreateAntiPhishingComponent, {
      width: '500px',
      disableClose: false
    });

    const createAntiphisingSub = dialogRef.afterClosed().subscribe(result => {
      createAntiphisingSub.unsubscribe();
    });
  }
  openSnackBar(message: string, className: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: [className],
      duration: 6 * 1000
    });
  }
}

@Component({
  selector: 'modify-anti-phishing',
  templateUrl: './modify-anti-phishing/modify-anti-phishing.component.html'
})

export class ModifyAntiPhishingComponent {
  Newcode: String
  phishingcode: string;
  createCode: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModifyAntiPhishingComponent>,
    public router: Router,
    public snackBar: MatSnackBar,
    public translate: TranslateService,
    private service: MutationService,
    public QueryService: QueryService,
    @Inject(MAT_DIALOG_DATA)
    public dialog: MatDialog) {
    this.checkAntiPhishingCode()
  }

  openSnackBar(message: string, className: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: [className],
      duration: 6 * 1000
    });
  }

  create() {
    if (this.Newcode.length < 4 || this.Newcode.length > 20) {
      this.openSnackBar(this.translate.instant("snackbar.enterNonSpecialCharacter"), 'red-snackbar')
    } else {
      let obj = {
        anti_phishing_code: this.Newcode
      }
      const ANTI_PHISHING_CODEQuery = this.service.mutation<AntiPhishingInput>(obj, ANTI_PHISHING_CODE).subscribe(res => {
        let response = res.data.antiPhishingCode as personalinforesponse
        if (response.status_code == 200) {
          this.checkAntiPhishingCode();
          this.dialogRef.close();
          this.router.navigate(["/security"]);
          this.openSnackBar(response.status_message, 'green-snackbar');
        } else {
          this.openSnackBar(response.status_message, 'red-snackbar');
        }
        ANTI_PHISHING_CODEQuery.unsubscribe();
      })
    }
  }

  checkAntiPhishingCode() {
    const GET_USER_BY_JWTQuery = this.QueryService.query(GET_USER_BY_JWT, null, 'network-only').subscribe(res => {
      let response = res.data.getUserByJWT
      if (response.result.anti_phishing_code == "") {
        this.createCode = true
      } else {
        let antiphishingcode = response.result.anti_phishing_code
        this.phishingcode = antiphishingcode.substring(0, 2) + "***"
        this.createCode = false
      }
      GET_USER_BY_JWTQuery.unsubscribe();
    })
  }
}

@Component({
  selector: 'create-anti-phishing',
  templateUrl: './create-anti-phishing/create-anti-phishing.component.html'
})

export class CreateAntiPhishingComponent {
  Newcode: String
  code: string;
  createCode: boolean = false;
  tfaStatus: string
  tfaenablekey: any;

  constructor(
    public dialogRef: MatDialogRef<CreateAntiPhishingComponent>,
    public router: Router,
    public snackBar: MatSnackBar,
    private service: MutationService,
    public translate: TranslateService,
    public QueryService: QueryService,
    private data: DataService,
    @Inject(MAT_DIALOG_DATA)
    public dialog: MatDialog) {
    this.checkAntiPhishingCode();
  }

  openSnackBar(message: string, className: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: [className],
      duration: 6 * 1000
    });
  }


  create() {
    if (this.tfaStatus !== 'unverified') {
      this.updateCode()
    } else {
      this.dialogRef.close();
      let obj = {
        tfaStatus: this.tfaStatus,
        type: 'anti-phishing-code'
      }
      this.data.changeMessage(obj)
      // this.GooglePopup()
    }
  }

  updateCode() {
    console.log("inside", this.Newcode)
    if (this.Newcode.length < 4 || this.Newcode.length > 20) {
      this.openSnackBar(this.translate.instant("snackbar.enterNonSpecialCharacter"), 'red-snackbar')
    } else {
      let obj = {
        anti_phishing_code: this.Newcode
      }
      const ANTI_PHISHING_CODEQuery = this.service.mutation<AntiPhishingInput>(obj, ANTI_PHISHING_CODE).subscribe(res => {
        let response = res.data.antiPhishingCode as personalinforesponse
        if (response.status_code == 200) {
          this.checkAntiPhishingCode();
          this.dialogRef.close();
          this.router.navigate(["/security"]);
          this.openSnackBar(response.status_message, 'green-snackbar');
        } else {
          this.openSnackBar(response.status_message, 'red-snackbar');
        }
        ANTI_PHISHING_CODEQuery.unsubscribe();
      })
    }
  }

  checkAntiPhishingCode() {
    const GET_USER_BY_JWTQuery = this.QueryService.query(GET_USER_BY_JWT, null, 'network-only').subscribe(res => {
      let response = res.data.getUserByJWT
      this.tfaStatus = response.result.tfa_status
      if (response.result.anti_phishing_code == "") {
        this.createCode = true
      } else {
        let antiphishingcode = response.result.anti_phishing_code
        this.code = antiphishingcode.substring(0, 2) + "***"
        this.createCode = false
      }
      GET_USER_BY_JWTQuery.unsubscribe();
    })
  }
}


@Component({
  selector: 'verify-tfa-code',
  templateUrl: './verify-tfa-code.component.html'
})

export class verfiyTfaCodeComponent {
  verify: any = {}
  code = ''
  token = ''
  @ViewChild('value1', { static: true }) value1: ElementRef;
  @ViewChild('value2', { static: true }) value2: ElementRef;
  @ViewChild('value3', { static: true }) value3: ElementRef;
  @ViewChild('value4', { static: true }) value4: ElementRef;
  @ViewChild('value5', { static: true }) value5: ElementRef;
  @ViewChild('value6', { static: true }) value6: ElementRef;
  values: any = [];

  constructor(
    public dialogRef: MatDialogRef<verfiyTfaCodeComponent>,
    public router: Router,
    public service: MutationService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public dialog: MatDialog) {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onKeyUp(event, index) {
    if (event.target.value.length != 1) {
      // this.setFocus(index - 2);
    } else {
      this.values.push(event.target.value);
      this.setFocus(index);
    }
    event.stopPropagation();
  }

  keyDownEvent(event, index) {
    // if(event.code === "Backspace" && event.target.value == "") {
    //   this.setFocus(index - 2);
    //   event.stopPropagation();
    // }

    if(event.code === "Backspace" && event.target.value == "") {
      this.setFocus(index - 2);
    } 

    if(!isNaN(event.key)) {
        if(index == 1 && this.verify.value1 && !isNaN(event.key)) {
            this.verify.value1 = event.key
        } else if(index == 2 && this.verify.value2) {
            this.verify.value2 = event.key
        } else if(index == 3 && this.verify.value3) {
            this.verify.value3 = event.key
        } else if(index == 4 && this.verify.value4) {
            this.verify.value4 = event.key
        } else if(index == 5 && this.verify.value5) {
            this.verify.value5 = event.key
        } else if(index == 6 && this.verify.value6) {
            this.verify.value6 = event.key
        }
    }
  }
  
  setFocus(index) {

    switch (index) {
      case 0:
        this.value1.nativeElement.focus();
        break;
      case 1:
        this.value2.nativeElement.focus();
        break;
      case 2:
        this.value3.nativeElement.focus();
        break;
      case 3:
        this.value4.nativeElement.focus();
        break;
      case 4:
        this.value5.nativeElement.focus();
        break;
      case 5:
        this.value6.nativeElement.focus();
        break;
    }
  }

  onPaste(event: ClipboardEvent) {
    this.verify.value1 = event.clipboardData.getData('text')[0]
    this.verify.value2 = event.clipboardData.getData('text')[1]
    this.verify.value3 = event.clipboardData.getData('text')[2]
    this.verify.value4 = event.clipboardData.getData('text')[3]
    this.verify.value5 = event.clipboardData.getData('text')[4]
    this.verify.value6 = event.clipboardData.getData('text')[5]
    this.value6.nativeElement.focus();
  }

  openSnackBar(message: string, className: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: [className],
      duration: 6 * 1000
    });
  }
  
  loading: boolean = false;
  submit() {
    this.loading = true;
    let code = this.code.concat(this.verify.value1, this.verify.value2, this.verify.value3, this.verify.value4, this.verify.value5, this.verify.value6)
    if ((this.token != "" || this.token != null || this.token != undefined) && code.length === 6) {
      this.loading = false;
      this.dialogRef.close(code)
    }
  }

  cantAccess() {
    this.dialogRef.close();    
    this.router.navigate(["/reset-google-tfa"], { queryParams: {
        token: this.token,
        email: localStorage.getItem("em")
      },
      skipLocationChange: true
    })
  }
}