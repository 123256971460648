import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment'
import { CookieService } from 'ngx-cookie-service'
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private url = environment.socketHost;
    public socket;
    constructor(private cookieService: CookieService) {
        
    }
    
    connect(){
        this.socket = io(this.url, {
            transports          : ["websocket"],
            withCredentials     : true,
            // autoConnect         : true,
            secure              : true,
            rejectUnauthorized  : false
        })
        // this.socket.listeners('connection')
        // console.log("listeners",listeners)
    }    

    disconnect(){        
        if( this.socket ){
            this.socket.disconnect()            
        }
    }   

    public sendMessage(message) {
        this.socket.emit('new-message', message);
    }
    
    public getPriceTicker = () => {
        return Observable.create((observer) => {
            this.socket.on('priceticker', (price) => {
                observer.next(price);
            });
        });
    }
    
    getCustomPriceTicker = (userPreferredCurrency) => {
        return Observable.create((observer) => {
            this.socket.on(userPreferredCurrency, (history) => {
                if (userPreferredCurrency == history.id) {
                    observer.next(history);
                }
            });
        });
    }
    
    public getMarketPriceTicker = () => {
        return Observable.create((observer) => {
            this.socket.on('priceticker', (price) => {
                observer.next(price);
            });
        });
    }
    
    public getTradeHistory = () => {
        return Observable.create((observer) => {
            this.socket.on('tradehistory', (history) => {
                observer.next(history);
            });
        });
    }
    
    public getYourTradeHistory = () => {
        return Observable.create((observer) => {
            this.socket.on('yourtradehistory', (history) => {
                if (this.cookieService.get('zodeak_tmp_user_str') == history.id) {
                    observer.next(history);
                }
            });
        });
    }
    
    public getWalletBalance = () => {
        return Observable.create((observer) => {
            this.socket.on('wallet balance', (balance) => {
                if (this.cookieService.get('zodeak_tmp_user_str') == balance.id) {
                    observer.next(balance);
                }
            });
        });
    }
    
    public getOpenOrderHistory = () => {
        return Observable.create((observer) => {
            this.socket.on('OpenOrderHistory', (history) => {
                if (this.cookieService.get('zodeak_tmp_user_str') == history.id) {
                    observer.next(history);
                }
            });
        });
    }
    
    public afterLoginGetPairs = () => {
        return Observable.create((observer) => {
            this.socket.on('afterLoginGetPairs', (history) => {
                if (this.cookieService.get('zodeak_tmp_user_str') == history.id) {
                    observer.next(history);
                }
            });
        });
    }
    
    public beforeLoginGetPairs = () => {
        return Observable.create((observer) => {
            this.socket.on('beforeLoginGetPairs', (price) => {
                observer.next(price);
            });
        });
    }
    
    public getOpenOrders = () => {
        return Observable.create((observer) => {
            this.socket.on('openOrders', (orders) => {
                observer.next(orders);
            });
        });
    }
    
    getOpenBook = (pair) => {
        return Observable.create((observer) => {
            this.socket.on(pair, (orders) => {
                if (pair == orders.id) {
                    observer.next(orders);
                }
            });
        });
    }
    
    public getBeforeLoginOpenOrders = () => {
        return Observable.create((observer) => {
            this.socket.on('beforeloginopenOrders', (orders) => {
                observer.next(orders);
            });
        });
    }
    
    public joinRoom = (pair, usrPrefCurrency) => {
        return Observable.create((observer) => {
            this.socket.emit("joinroom", `${pair}_${usrPrefCurrency}`)
            this.socket.on('message', (data) => {
                observer.next(data);
            });
        });
    }
    
    public leaveRoom = (pair, usrPrefCurrency) => {
        return Observable.create((observer) => {
            this.socket.emit("leaveroom", `${pair}_${usrPrefCurrency}`)
        });
    }
    
    public joinTradePairRoom = () => {
        return Observable.create((observer) => {
            this.socket.emit("joinTradePairRoom", "trade_pair_room")
            this.socket.on('trade_pairs', (data) => {
                observer.next(data);
            });
        });
    }
    
    public leaveTradePairRoom = () => {
        return Observable.create((observer) => {
            this.socket.emit("leaveTradePairroom", "trade_pair_room")
        });
    }

    public getSocTradePairs = (pair) => {        
        return Observable.create((observer) => {
            this.socket.on(`trade_pair_${pair}`, (history) => {
                observer.next(history);
            });
        });
    }

    public offSocTradePairs = (pair) => {
        this.socket.off("trade_pair_"+pair)
    }

    public getSocTradeHistory = (pair) => {        
        return Observable.create((observer) => {
            this.socket.on("tradeHistory_"+pair, (history) => {
                observer.next(history);
            });
        });
    }

    public getSocYoursTradeHistory = (pair, socketId) => {        
        return Observable.create((observer) => {
            this.socket.on(`tradeYoursHistory_${socketId}_${pair}`, (history) => {
                observer.next(history);
            });
        });

        // return new Observable((subscriber) => {
        //     this.socket.on(`tradeYoursHistory_${socketId}_${pair}`, (data) => {
        //         subscriber.next(data);
        //     })
        // })
    }

    public getSocWalletBalance = (socketId) => {        
        return Observable.create((observer) => {
            this.socket.on(`walletBalance_${socketId}`, (data) => {
                observer.next(data);
            });
        });
    }

    public getSocMyOpenOrders = (socketId) => {        
        return Observable.create((observer) => {
            this.socket.on(`myOpenOrders_${socketId}`, (data) => {
                observer.next(data);
            });
        });
    }

    // public offSocYoursTradeHistory = (pair, socketId) => {
    //     this.socket.off(`tradeYoursHistory_${socketId}_${pair}`)
    // }

    public offSocTradeHistory = (pair) => {
        this.socket.off("tradeHistory_"+pair)
    }

    public getSocOpenOrders = (pair) => {        
        return Observable.create((observer) => {
            this.socket.on("tradeOpenOrders_"+pair, (history) => {
                observer.next(history);
            });
        });
    }

    public getSocKlines = (pair) => {        
        return Observable.create((observer) => {
            this.socket.on(`klines_${pair}`, (kline) => {
                observer.next(kline);
            });
        });
    }

    public offSocKlines = (pair) => {
        if(this.socket && pair ){
            this.socket.off("klines_"+pair)
        }
    }

    public offSocOpenOrders = (pair) => {        
        this.socket.off("tradeOpenOrders_"+pair);
    }

    public joinChatRoom = (orderId) => {
        return Observable.create((observer) => {
            this.socket.emit("joinChatRoom", `${orderId}`)
            this.socket.on('message', (data) => {
                observer.next(data);
            });
        });
    }
    
    public sentMessage = (data, orderId) => {
        this.socket.emit(orderId, data)
    }
    
    //joinOrderRoom
    public joinOrderRoom = (orderId) => {
        return Observable.create((observer) => {
            this.socket.emit("joinOrderRoom", `${orderId}_order`)
            this.socket.on('orderUpdate', (data) => {
                console.log("data received order", data)
                observer.next(data);
            });
            this.socket.on('feedback', (data) => {
                console.log("data received order", data)
                observer.next(data);
            });
        });
    }

    public joinFeedbackRoom = (orderId) => {
        return Observable.create((observer) => {
            this.socket.emit("joinOrderRoom", `${orderId}_order`)
            this.socket.on('feedback', (data) => {
                console.log("data received order", data)
                observer.next(data);
            });
        });
    }

    public leaveFeedbackRoom = (orderId) => {
        return Observable.create((observer) => {
            this.socket.emit("leaveOrderRoom", `${orderId}_order`)
        });
    }
    
    public leaveOrderRoom = (orderId) => {
        return Observable.create((observer) => {
            this.socket.emit("leaveOrderRoom", `${orderId}_order`)
        });
    }
    
    public leaveChatRoom = (orderId) => {
        return Observable.create((observer) => {
            this.socket.emit("leaveChatRoom", `${orderId}`)
        });
    }

    joinTVChartRoom = (pair) => {
        return Observable.create((observer) => {            
            this.socket.emit("joinTVChart", pair)
            this.socket.on('tvChartMessage', (data) => {
                observer.next(data);
            });
        });
    }

    leaveTVChartRoom = (pair) => {
        return Observable.create((observer) => {
            this.socket.emit("leaveTVChart", pair)
        });
    }
    
    public offSocYoursTradeHistory = (pair) => {
        this.socket.off("tradeYoursHistory_"+pair)
    }

    public offSocWalletBalance = (pair) => {
        this.socket.off("walletBalance_"+pair)
    }

    // Margin Trade Socket //
    public getMarginWalletData = (socketId) => {
        return Observable.create((observer) => {
            this.socket.on(`marginWalletBalance_${socketId}`, (data) => {                
                observer.next(data);
            });
        });
    }

    public getMarginOpenOrders = (socketId) => {
        return Observable.create((observer) => {
            this.socket.on(`myMarginOpenOrders_${socketId}`, (data) => {                
                observer.next(data);
            });
        });
    }

    public getMarginPosition = (socketId) => {
        return Observable.create((observer) => {
            this.socket.on(`myMarginPositions_${socketId}`, (data) => { 
                observer.next(data);
            });
        });
    }
    // Margin Trade Socket //

    // Home Page Socket //
    public getCurrencydata = (currency) => {
        return Observable.create((observer) => {
            this.socket.on(`currency`, (data) => { 
                observer.next(data);
            });
        });
    }
    // Home Page Socket //
}
