import gql from 'graphql-tag';

export const GET_DEFAULT_CURRENCY = gql`query getDefaultCurrency{
  getDefaultCurrency {
    status_code
    status_message
    result{
      fiat_default_currency
      crypto_default_currency
      site_name
      site_logo
      favicon
      site_banner
      binance_status
      site_description
      banner_title
      crypto_currency_api_key
      join_us{
        Facebook
        Twitter
        Linkedin
        Youtube
        Instagram
        Play_store
        App_store
        contact_mail
      }
      recaptcha{
        recaptcha_sitekey
        recaptcha_secretkey
      }
      referral_settings {
        earning_limit
        commission_percentage
        referral_link_limit
        referral_default_currency
      }
      site_maintenance
      margin_settings {
        cross_leverage
        initial_risk_ratio_cross
        mcr_cross
        liquidation_ratio_cross
        mcr_isolated {
          x3
          x5
          x10
        }
        initial_risk_ratio_isolated {
          x3
          x5
          x10
        }
        liquidation_ratio_isolated {
          x3
          x5
          x10
        }
      }
    }
  }
}`

export const registerSiteSettings = gql`query getDefaultCurrency{
  getDefaultCurrency {
    status_code
    status_message
    result{
      recaptcha{
        recaptcha_sitekey
        recaptcha_secretkey
      }
    }
  }
}`

export const appSiteSettings = gql`query getDefaultCurrency{
  getDefaultCurrency {
    status_code
    status_message
    result{
      favicon
      site_maintenance

    }
  }
}`

export const footerSiteSettings = gql`query getDefaultCurrency{
  getDefaultCurrency {
    status_code
    status_message
    result{
      site_name
      join_us{
        Facebook
        Twitter
        Linkedin
        Youtube
        Instagram
        Play_store
        App_store
        contact_mail
      }
    }
  }
}`

export const headerSiteSettings = gql`query getDefaultCurrency{
  getDefaultCurrency {
    status_code
    status_message
    result{
      fiat_default_currency
      crypto_default_currency
      site_logo
    }
  }
}`

export const loginSiteSettings = gql`query getDefaultCurrency{
  getDefaultCurrency {
    status_code
    status_message
    result{
      recaptcha{
        recaptcha_sitekey
        recaptcha_secretkey
      }
    }
  }
}`

export const GET_EXCHANGE_RATE = gql`query getExchangeRate($data: getExchangeRateDataInput){
  getExchangeRate(getExchangeRateData: $data) {
    status_code
    status_message
    result{
      _id
      from_currency_code
      to_currency_code
      exchange_rate
    }
  }
}`


export const GET_REFRESH_TOKEN = gql`
  mutation getRefreshToken($input: refreshTokenInput) {
    getRefreshToken(refreshTokenData: $input) {
      status_code
      status_message
      result{
        token
        email
      }
    }
  }
`;

export const DEVICE_LOGOUT = gql`
  mutation deviceLogout($input: logoutInput) {
    deviceLogout(logoutData: $input) {
      status_code
      status_message
    }
  }
`;


export const UPDATE_USER_LANG = gql`
  mutation updateUserLanguage($input: updateUserLanguageInput) {
    updateUserLanguage(updateUserLanguageCode: $input) {
      status_code
      status_message
    }
  }
`;

export const CMS_TYPE = gql`
query getCMSType($CMSTypeData: getCMSTypeInput) {
  getCMSType(getCMSTypeData: $CMSTypeData) {
    status_code
    status_message
    result {
      _id
      lang
      status
      title
    }
  }
}`;