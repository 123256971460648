import { Component } from '@angular/core';
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material';
import * as  publicIp from 'public-ip';
import cryptojs from 'crypto-js';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { AuthService } from './services/auth.service'
import { QueryService } from './services/query.service';
import { siteSettingsResponse } from '../app/header/types/header.output.types';
import { appSiteSettings } from '../app/header/types/header.query.types';
import { CookieService } from 'ngx-cookie-service';
import {DeviceDetectorService}  from 'ngx-device-detector';
import { environment } from "../environments/environment";
import { GraphQLModule } from './graphql.module';
import {TranslateService} from '@ngx-translate/core';

const key = environment.cryptoJsKey
const keyutf = cryptojs.enc.Utf8.parse(key)
const iv = cryptojs.enc.Utf8.parse(key)

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'client';
  showHeader: boolean;
  showFooter: boolean;
  ipAddress:string;
  deviceInfo = null;
  enable:boolean = false;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  attr = document.documentElement;

  constructor(
    private cookieService: CookieService,
    public translate: TranslateService,
    location: Location,
    public router: Router,
    public AuthService: AuthService,
    public snackBar: MatSnackBar,
    private QueryService: QueryService,
    public deviceService: DeviceDetectorService,
    private graphqlModule: GraphQLModule
  ) {
    this.attr.setAttribute('lang' , localStorage.getItem("lang"));
    (localStorage.getItem("lang") === "null") ? localStorage.setItem("lang","en") : null
    translate.setDefaultLang(localStorage.getItem("lang"));
    translate.use(localStorage.getItem("lang"));
  
    // console.log(environment);
    if(environment.production || environment.live) {
      // <!-- Start of Tawk.to Script--> here 'ZEDPAY' is implemented also like 'TAWK' but ZEDPAY needs 'ID' 
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function() {
          var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
          s1.setAttribute("id", "ze-snippet");
          s1.async=true;
          // s1.src='https://embed.tawk.to/5d764a9077aa790be3332457/default';
          s1.src='https://static.zdassets.com/ekr/snippet.js?key=76944491-1fee-4fc3-a3e1-28682a47febd';
          s1.charset='UTF-8';
          s1.setAttribute('crossorigin','*');
          s0.parentNode.insertBefore(s1,s0);
        })();
      // <!--End of Tawk.to Script--> here 'ZEDPAY' is implemented also like 'TAWK' but ZEDPAY needs 'ID' 
    }

  //  window.addEventListener("onbeforeunload", (event) => {
  //     let messge = "message added"
  //     // console.log(event)
  //     //  event.returnValue = messge
  //     // return messge;
  //     //return "check"
  //     // this.cookieService.delete('registerMail');
  //     // this.cookieService.delete('x-access-token');
  //     // this.cookieService.delete('zodeak_tmp_user_str');
  this.favIcon.href = "../assets/img/zedx_fav_icon.png";
  // });
    // @HostListener("window:unload", ["$event"]) unloadHandler(event: Event) {
  //   console.log("Processing beforeunload...", event);
  //   return event;
  // }

  this.deviceInfo = this.deviceService.getDeviceInfo();
  // const accessToken = localStorage.getItem("x-access-token")
  const accessToken = this.cookieService.get("x-access-token")
    if((!accessToken || accessToken == "" || accessToken === undefined || accessToken === null) &&
      (
        location.path().split("/")[1] !== "markets" &&
        location.path().split("/").length !== 1) &&
        (location.path().split("/")[1] !== "register") &&
        (location.path().split("/")[1] !== "faq") &&
        (location.path().split("/")[1] !== "terms-and-condition") &&
        (location.path().split("/")[1] !== "about") &&
        !location.path().split("/")[1].includes("register") &&
        (location.path().split("/")[2] && location.path().split("/")[2] !== "invite-friends")
    ) {     
      console.log("Test login", location.path().split("/"));
      
      // router.navigate(["/login"]);
      this.graphqlModule.getLogout();
    }
    if (AuthService.isLoggedIn()) {
      router.events.subscribe(val => {
        if (location.path() == "/advanced-exchange/ETH_BTC") {
          this.showHeader = false;
          this.showFooter = false;
        }
        else {
          this.showHeader = true;
          this.showFooter = true;
        }
      });
    } else {
      let urlsplit = location.path().split('?')
      if (urlsplit[0] == "/wallet/crypto-withdraw") {
        this.openSnackBar(this.translate.instant("snackbar.invalidRequest"), 'red-snackbar')
        router.navigate(['/login']);
      } else {
        this.showHeader = true;
          this.showFooter = true;
      }
    }
  }

  openSnackBar(message: string, className: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: [className],
      duration: 6 * 1000
    });
  }

  ngOnInit() {
    // this.AuthService.loaderCurrentStatus.subscribe(msg =>{            
    //   // this.enable = msg
      
    // });

    this.QueryService.query(appSiteSettings, null, 'network-only').subscribe(data => {
      let response = data.data.getDefaultCurrency as siteSettingsResponse
      if(response){
        this.favIcon.href = response.result.favicon.toString();
        const siteMaintenance = response.result["site_maintenance"];
        if(siteMaintenance) {
          this.router.navigate(['/site-maintenence'], { skipLocationChange: true });
        }
      }
    })
    this.getIP()
  }
  getIP = async() => {
      const cryptoIp = cryptojs.AES.encrypt(await publicIp.v4(), keyutf, { iv: iv });
      const cryptoBroswer =  cryptojs.AES.encrypt(this.deviceInfo.browser, keyutf, { iv: iv });
      // localStorage.setItem("x-lid",cryptoIp)
      // localStorage.setItem("x-dd",cryptoBroswer)

      this.cookieService.set("x-lid",cryptoIp)
      this.cookieService.set("x-dd",cryptoBroswer)
      localStorage.setItem("x-lid",cryptoIp)
  } 

}
