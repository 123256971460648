import gql from 'graphql-tag';

export const GETFIATCURRENCY = gql`query getFiatCurrency{
  getFiatCurrency{
    status_code
    status_message
    result{
      currency_code
      min_withdraw_limit
      withdraw_fee
    }
  }
}`

export const FIATDEPOSITHISTORY = gql`query FiatDepositHistory{
  FiatDepositHistory{
    status_code
    status_message
    result{
      currency_code
      transaction_id
      pay_mode
      status
      amount
      modified_date
    }
  }
}`

export const GETBANKDETAILS = gql`query getAdminBankDetails{
  getAdminBankDetails {
    status_code
    status_message
    result{
      admin_account_name
      admin_account_number
      bank_name
      bank_routing_sort_code
      bank_swift_bic_code
      bank_address
      bank_state
      bank_country
      bank_postal_zip_code
    }
  }
}`

export const getBraintreeClientToken = gql`query
getBraintreeClientToken{
  getBraintreeClientToken{
    status_code
    status_message
    result {
      clientToken
      success
    }
  }
}`

export const getPaymentCurrencyExchangeRate = gql`query getPaymentCurrencyExchangeRate($data: getPayCurrencyExRateInput) {
  getPaymentCurrencyExchangeRate(getPayCurrencyExRateData: $data) {
    status_code
    status_message
    result {
      from_currency_code
      to_currency_code
      exchange_rate
    }
}
}`

export const getCurrencyByCode = gql`
query getCurrencyByCode($data: getCurrencyInput) {
  getCurrencyByCode(getCurrencyData: $data) {
    status_code
    status_message
    result {
      _id
      code
      min_withdraw_limit
      max_withdraw_limit
    }
  }
}`