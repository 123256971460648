import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { QueryService } from './query.service';
import { appSiteSettings } from '../header/types/header.query.types';

@Injectable({ providedIn: 'root' })
export class SiteMaintenanceService {
  
  constructor(    
    private router      : Router,
    private queryService: QueryService,
  ){}
  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    this.queryService.query(appSiteSettings, null, 'network-only').subscribe(data => {
      let response = data.data.getDefaultCurrency
      if(response){
        const siteMaintenance = response.result["site_maintenance"];
        if(siteMaintenance){
          this.router.navigate(["/site-maintenence"], { skipLocationChange: true });
          return false;
        }else{
          return true;
        }
      }
    })
    return true;
  }
}
