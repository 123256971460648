import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MenuComponent } from "./menu/menu.component";
import { SubMenuComponent } from "./sub-menu/sub-menu.component";
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { NumericDirective } from '../onlynumber.directive'
import { FiatNumericDirective } from '../fiatnumber.directive'
import { TranslateModule } from '@ngx-translate/core';
import { CustomDatePipe } from '../custom.datepipe';
import { TrimValuePipe } from '../trim-input-value';
import { MatTabsModule } from '@angular/material';
import { KeysPipe } from '../list-key-object';
@NgModule({
  declarations: [
    MenuComponent,
    SubMenuComponent,
    BreadcrumbComponent,
    NumericDirective,
    FiatNumericDirective,
    CustomDatePipe,
    TrimValuePipe,
    KeysPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatTabsModule
  ],
  exports: [
    MenuComponent,
    SubMenuComponent,
    BreadcrumbComponent,
    NumericDirective,
    FiatNumericDirective,
    TranslateModule,
    CustomDatePipe,
    TrimValuePipe,
    KeysPipe
  ]
})
export class SharedModule { }
