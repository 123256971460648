import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HeaderService } from '../../services/header.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  username: String = '';
  mail: String = "";

  constructor(private cookieService: CookieService, public headerService: HeaderService) {
    // let email = localStorage.getItem('registerMail')
    let email = this.cookieService.get('registerMail')
    if (email != null || email != undefined) {
      let splitted = email.split("@");
      this.username = splitted[0].substring(0, 2)
      this.mail = this.username + "***@" + splitted[1];
    }
  }

  ngOnInit() {
    // this.headerService.mailTemp = localStorage.getItem('registerMail');
    this.headerService.mailTemp = this.cookieService.get('registerMail');
  }

}
