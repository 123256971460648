// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production : false,
  backendHost: "https://clientp2p.zed-pay.com/graphql",
  socketHost: "https://clientp2p.zed-pay.com:6556",
  cryptoJsKey: "8jMU13pQKQ4UCSR+WGRxuMmYiaqt04DZWeHgUvq7ihlvFekBU+Wi9Mk837BXuQa7",
  // binanceSocket:  "wss://stream.binance.com:9474/stream?streams=",
  binanceSocket: "wss://testnet.binance.vision/stream?streams=",
  binanceApiUrl: "https://api.binance.com/api/v3/",
  client_url: "https://bzbroker.zodeak-dev.com",
  live: false
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.