import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimValues'
})
export class TrimValuePipe implements PipeTransform {
  transform(value: string | number, args?: number): string | number {    
    !args ? args = 8 : args = args 
    !value ? value = '0.00' : value = value 
    typeof value != 'number' ? value = +value : value = value
    value = value.toFixed(args)   
    value === '0.00000000' ? value = '0.00' : value = value.replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1')

    if(!value.toString().includes(".")){
        value = value + '.' + '00'
    }
    
    return value;
  }
}